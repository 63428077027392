import logo from './logo.svg';
import './App.css';

function App() {
    return (
        <div className="content">
            <img src={logo} className="app-logo" alt="logo" />
            <div className="text-content">
                <h1>See you soon.</h1>
                <h3>We’re revolutionizing the way you progress in your fitness journey.</h3>
            </div>
        </div>
    );
}

export default App;
